import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../styles/work.scss'
import '../styles/work-item.scss'

const IndexPage = ({data}) => {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
        <Layout>
            <SEO title="Home" />
            <main className='work work-item'>
                <article>
                    <h2>{frontmatter.title} <a href={frontmatter.link} target='_blank' rel="noreferrer"><span role='img' aria-label="link">🔗</span></a></h2>
                    <div dangerouslySetInnerHTML={{ __html: html }}>
                    </div>
                    <Link to='/work'>BACK</Link>
                </article>
            </main>
            <span><small>&copy; Nathan Akrill</small></span>
        </Layout>
    )
}

export default IndexPage
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        link
      }
    }
  }`
